// src/App.js
import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import ReactPlayer from 'react-player';

function App() {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [isFileAudio, setIsFileAudio] = useState(true);
  const [loading, setLoading] = useState(false);

  // const onDrop = (acceptedFiles) => {
  //   const [uploadedFile] = acceptedFiles;
  //   setFile(uploadedFile);
  //   handleUpload(uploadedFile);
  // };

  const onDrop = (acceptedFiles) => {
    const [uploadedFile] = acceptedFiles;
    const fileType = uploadedFile.type.split('/')[0];
    if (fileType === 'audio') {
      setFile(uploadedFile);
      setIsFileAudio(true);
    } else {
      setIsFileAudio(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'audio/*'
  });

  const handleUpload = (file) => {
    setUploading(true);
    const formData = new FormData();
    formData.append('audio', file);

    axios.post('YourExternalAPI/upload-audio', formData)
      .then(response => {
        setVideoUrl(response.data.videoLink);
      })
      .catch(error => {
        console.error('Error uploading file:', error);
      })
      .finally(() => {
        setUploading(false);
      });
  };


  if (videoUrl) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl mb-4 font-bold">MusicVideoAI</h1>
        <p>Your personal AI that creates music videos from your audio files.</p>
        <ReactPlayer url={videoUrl} controls={true} playing={true} width='100%' height='100%' />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4 font-bold">MusicVideoAI</h1>
      <p>Welcome to MusicVideoAI, the app that creates stunning videos from your music tracks. To get started, upload an audio file of your song and our AI will handle the rest!</p>
     <div {...getRootProps({ className: 'dropzone border-dashed border-4 border-gray-200 rounded-lg p-8 mt-4 cursor-pointer' })}>
        <input {...getInputProps()} />
        {file ? (
          <div>
            <p className="text-green-500">{file.name}</p>
            {isFileAudio && (
              <button 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                type="button"
                onClick={handleUpload}
              >
                Create Video
              </button>
            )}
          </div>
        ) : (
          <p>Drag 'n' drop your audio file here, or click to select a file</p>
        )}
      </div>
      
      {!isFileAudio && (
        <div className="text-red-500 mt-4">Please upload a valid audio file.</div>
      )}

      {uploading && <div className="loader mt-4">Uploading...</div>}
      
      {videoUrl && (
        <ReactPlayer url={videoUrl} controls={true} playing={true} width='100%' height='100%' />
      )}
    </div>
  );
}

export default App;